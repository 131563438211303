
@import "bootstrap.less";
@import "navbar-custom.less";
@import "buttons-custom.less";

// BS Variables
@brand-primary: #990134; //matcher kirsche
// Custom
@brand-cancel: #999;
@brand-oprimary: #ff6600;
@brand-gray1: #ddd;

@border-radius-base:        0px;
@border-radius-large:       0px;
@border-radius-small:       0px;

@nav-tabs-active-link-hover-bg:             #ccc;
@nav-tabs-active-link-hover-color:          #555;

// Custom
@close-white-color:                 #fff;
@close-white-text-shadow:           0 1px 0 #000;

// Custom
@btn-cancel-color:              #fff;
@btn-cancel-bg:                 @brand-cancel;
@btn-cancel-border:             darken(@btn-cancel-bg, 5%);

@btn-oprimary-color:              #fff;
@btn-oprimary-bg:                 @brand-oprimary;
@btn-oprimary-border:             darken(@btn-oprimary-bg, 5%);

@btn-gray1-color:              #333;
@btn-gray1-bg:                 @brand-gray1;
@btn-gray1-border:             darken(@btn-gray1-bg, 10%);


// Custom
@btn-border-radius-base:        4px;
@btn-border-radius-large:       6px;
@btn-border-radius-small:       3px;

// Basics of a navbar
@navbar-height:                    50px;
@navbar-margin-bottom:             @line-height-computed;
@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        floor((@grid-gutter-width / 2));
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2);
@navbar-collapse-max-height:       340px;

@navbar-mdefault-color:             #fff; //#777;
@navbar-mdefault-bg:                @brand-primary; //#f8f8f8;
@navbar-mdefault-border:            darken(@navbar-mdefault-bg, 6.5%);

// Navbar NEW
@navbar-mdefault-link-color:                #fff; //#777;
@navbar-mdefault-link-hover-color:          #ddd; //#333;
@navbar-mdefault-link-hover-bg:             transparent;
@navbar-mdefault-link-active-color:         #eee; //#555;
@navbar-mdefault-link-active-bg:            darken(@navbar-default-bg, 65%);
@navbar-mdefault-link-disabled-color:       #ccc;
@navbar-mdefault-link-disabled-bg:          transparent;

// Navbar brand label
@navbar-mdefault-brand-color:               @navbar-mdefault-link-color;
@navbar-mdefault-brand-hover-color:         darken(@navbar-mdefault-brand-color, 10%);
@navbar-mdefault-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-mdefault-toggle-hover-bg:           #ddd;
@navbar-mdefault-toggle-icon-bar-bg:        #888;
@navbar-mdefault-toggle-border-color:       #ddd;



// Save in a File ?  
// Custom .close-white (basis: .close)
.close-white {
  float: right;
  font-size: (@font-size-base * 1.5);
  font-weight: @close-font-weight;
  line-height: 1;
  color: @close-white-color;
  text-shadow: @close-white-text-shadow;
  .opacity(1.0);

//  &:hover,
//  &:focus {
//    color: @close-white-color;
//    text-decoration: none;
//    cursor: pointer;
//    .opacity(.7);
//  }


  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  button& {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}

// Button Mixin
//
.button-variant(@color; @background; @border) {
  color: @color;
  background-color: @background;
  border-color: @border;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color;
    background-color: darken(@background, 10%);
        border-color: darken(@border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: @background;
          border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

